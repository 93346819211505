
.Approval_button{

  justify-content:space-around;
  
  
  
  }
  .wid{
    background-color:#43baa0;
    color: white;
    padding:2px;
    border:none;
    border-radius: 4px;
    width: 50px;
    height:27px
  }
  .wid2{
    background-color:#43baa0;
    color : white;
    padding:2px;
    border:none;
    border-radius: 4px;
    width: 73px;
    height:27px
  }
  .main_bid_price{
    position:absolute; bottom: 165px;margin-left: 11px;font-family: monospace;
    display: grid;
    gap: 9px;
  }
  .divissio{
    display: flex;
      font-family: 'Roboto',sans-serif;
      text-align: center;
      font-size: 20px;
      justify-content: space-between;
      font-weight: 600;
    
      gap :10px
  
  }
  .divissio>link{
    width: 100px
  }
  
  .nav__men{
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: rgb(147, 88, 115);
  
    font-size: 20px;
    font-weight: 7;
    height: 60px;
    margin-right: 0px;
    
  }
  .btnn2{
    display: flex;
    align-items: end;
    justify-content: end;
  }
  .Vat-AMT{
    margin-left: 39px;
    background: transparent;
      border: none;
      border-bottom: 1.5px solid #565050;
  font-size: larger;
  }
  .Vat-AMTT{
    margin-left: 123px;
    background: transparent;
      border: none;
      border-bottom: 1.5px solid #565050;
      font-size: larger;
  }
  .Vat-AMTT1{
    margin-left: 68px;
    background: transparent;
      border: none;
      border-bottom: 1.5px solid #565050;
      font-size: larger;
  }
  .Vat-AMTT2{
    margin-left: 4px;
    background: transparent;
      border: none;
      border-bottom: 1.5px solid #565050;
      font-size: larger;
  }
  .apply_bid{
    visibility: visible;
    opacity: 1;
    height: 2vh;
 
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 4px;
   
   
  text-decoration: none;
    border-radius: 10px;
    font-size: 20px;
    margin-left: 2px;
    background-color: #43baa0;
    margin-top: 9px;
  }
  .apply_bid1{
    visibility: visible;
    opacity: 1;
    height: 2vh;
    width: 95px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0px;
   
   
  text-decoration: none;
    border-radius: 10px;
    font-size: 20px;
    margin-left: 2px;
    background-color: #43baa0;
    margin-top: 9px;
  }
  
  .ribbon {
    
    position: relative;
    margin-bottom: 30px;
    background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
    background-size: cover;
    text-transform: uppercase;
    color: white;
  }
  .wrap {
    width: 100%;
    height: 188px;
    position: absolute;
    top: -8px;
    left: 8px;
    overflow: hidden;
  }
  .wrap:before, .wrap:after {
    content: ""; 
    position: absolute;
  }
  .wrap:before {
    width: 40px;
    height: 8px;
    right: 100px;
    background: #4D6530;
    border-radius: 8px 8px 0px 0px;
  }
  .wrap:after {
    width: 8px;
    height: 40px;
    right: 0px;
    top: 100px;
    background: #4D6530;
    border-radius: 0px 8px 8px 0px;
  }
  .ribbon6 {
    width: 200px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 30px;
    right: -50px;
    z-index: 2;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 1px dashed;
    box-shadow:0 0 0 3px #57DD43,  0px 21px 5px -18px rgba(0,0,0,0.6);
    background: #57DD43;
    text-align: center;
  }
  .ribbon5 {
    display: block;
    width: calc(100% + 20px);
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-left: -10px;
    margin-right: -10px;
        background-color: #43baa0;
    position: relative;
    top: 30px;
    font-size: 20px
  }
  .ribbon5:before, .ribbon5:after {
    content: "";
    position: absolute;
  }
  .ribbon5:before {
    height: 0;
    width: 0;
    bottom: -10px;
    left: 0;
    border-top: 10px solid#43baa0;
    border-left: 10px solid transparent;
  }
  .ribbon7{
    display: block;
    width: calc(100% + 65px);
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-left: -32px;
    margin-right: -69px;
    background-color: #43baa0;
    position: relative;
    top: 20px;
  }
  .ribbon7:before, .ribbon7:after {
    content: "";
    position: absolute;
  }
  .ribbon7:before {
    height: 0;
    width: 0;
    bottom: -10px;
    left: 0;
    border-top: 10px solid#43baa0;
    border-left: 10px solid transparent;
  }
  .ribbon5:after {
    height: 0;
    width: 0;
    right: 0;
    bottom: -10px;
    border-top: 10px solid#43baa0;
    border-right: 10px solid transparent;
  }
  .ribbon7:after {
    height: 0;
    width: 0;
    right: 0;
    bottom: -10px;
    border-top: 10px solid#43baa0;
    border-right: 10px solid transparent;
  }
  .col-3{float: left;  position: relative;    width: 200px;}
 
  .effect-1 
  {border: 0; padding: 7px 0; border-bottom: 1px solid #433f3f;}
  /* .effect-1:hover{  position: absolute;
  
    content: '';
    height: auto;
    width: 190px;
    border-bottom: solid 1.5px #01b62b;
    
   } */
    .effect-1:hover:after {
      opacity: 1;
    }
  
 
  
  .Search2{
    display: flex;
    justify-content: start;
    margin-left: 20px; 
     gap:30px;
     padding: 20px;
  }
  @media only screen and (max-width: 600px) {
  .Search2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: 20px;
    gap: 30px;
    padding: 20px;
  }
  .col-3{float: left;     width: 200px;}
  }
  
  
  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
  }
  
  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
