.Request{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin:10px



  
}
.Request>div{
  display:flex;
}
.Request1{
  display: flex;
 gap:20px;
  align-items: center;
  margin:10px;
  padding: 6px
}
.Request2{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin:10px
}
.Request2>div{
  display: flex;}

.narration{

  display: flex;
  justify-content: center;
}
.full_length{
  width:100px
}
.Req_for>div{
display: flex;
/* justify-content: space-between; */
    gap: 30px;
    padding: 5px;
}
.Narration2{
  width: 70px;
}
.Narration_req{
  width: 126px;
}
.Req_for>.form__group_field1{
  display: flex;
  justify-content: space-between;
      gap: 65px;
      padding: 5px;
      margin-right: 180px;
  }
  .effect_8{
    border : 0;
    border-bottom: 1px solid ;
    width: 157px;
    margin-right:30px;
  }
.Request1>div{
  display: flex;
  }
.Request>div>p , .Request1>div>p ,.Request2>div>p{
width: 90px;
}
.Request1>div>.Name1 , .Request1>div>.Name ,.Request2>div>p{
  width: 60px;
  }
   .Request1>div>.Name2 ,.Request2>div>p{
    width: 126px;
    }
    .Request1>div>.Name1 ,.Request2>div>p{
      width: 75px;
      }
.Request>p{
  width: -1px;
}
.col{
  display:flex;
  gap:30px

}
.Request6>div>p {
width: 125px;
}
.Request6{
 
  display: flex;
  justify-content: space-around;
  align-items: inherit;
  margin:10px
}
.Request6>div{
  display: flex;
  }

.textarea{

 min-width:310px;
 max-width:310px;
 min-height: 100px;
 max-height: 100px;

}
.boxshadow{

margin-top: 10px;

box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
margin-left: 15px;
margin-right: 15px;
background-color: rgb(240, 255, 240)
}
.log_btn3{
  margin-left: 14px;
  margin-top: 8px;
  background-color: #43baa0 ;

  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  font-size: 18px;
  border: 0;
}
.Save_update{
 
  margin-top: 8px;
  background-color:  #43baa0;
 
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
 
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  font-size: 18px;
  border: 0;
}

.log_btn3:hover {
  background: #00bd68;
}
.box-shadow1{
  margin-top: 10px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
margin-left: 15px;
margin-right: 15px;
padding: 0px 0px 20px 0px;
background-color: white
}
.Req{
  display: flex;
align-items: start;
  margin:10px;
  gap:40px;
  margin-left: 15px;
}
.Req>p{
  width:100px
}
.V{

  background: transparent;
    border: none;
    width:10%
    
}


.table_row1{
  
    font-weight: 600;
    font-size: 15px;
    color: #100f0f;
    border-bottom: solid 5px;
    height: 30px;
    background-color: #ffffff;

    overflow-x: auto;
    width: 90px;
}
.table, .td, .th {  
  border: 2px solid #ddd;
  text-align: left;
}

.table {
  border-collapse: collapse;
  width: 100%;

}


.tr{
  background-color: #43baa0;
  padding: 2px
}
.input{
  height: 30px;
}
.btnd{
  background-color: #e76c63;
  border-radius: 2px;
  border:none;
  height:30px;
  color: white
}
.Grand{
  margin-left: 20px;
  margin-top: 20px;

 
}
.Accept_Bid{
  background-color:  #43baa0;;
 
  margin-left: 20px;
  margin-top: 18px;
  border:none;
  border-radius:3px;
  height: 40px;
  color: white
}
.total_budget{
  display: flex;
  padding-left:12px;
  margin:9px;
  background: transparent;
 

 
  height: 33px;
  
}
.Tab{
  margin-left: 15px;
    margin-right: 15px;
}
.View_detials{
  background-color:  #43baa0;

  height:30px;

 color : white;
  border:none;
  border-radius:3px
}
.Search2>diV{
  display: flex;
  gap:12px;
  font-size: 20px;
}
.filter-brand{
  width: 143px;
  background: transparent;
    border: none;
    outline: none;
    border-bottom: 1.5px solid #565050;
}
.option {
  border-radius: 2px;
  position: relative;
  display: inline-block;
  line-height: 1.5;
  width: 100%;
  margin: 0 0 0.25rem 0;
  background: tomato;
  transition:#ece5da 3s;
  box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.35);
  display: block;
  color: inherit;
  text-transform: lowercase;
  font-weight: 200;
  text-decoration: none;
}
.Item_code{
  width : 200px;
  border:none;
}
.Date_of_creation2{
  width:200px
}
@media only screen and (max-width: 600px){
  .Search2>diV {
    display: flex;
    gap: 12px;
    font-size: 20px;
    width: 337px;

  }
  .Req_for>{
    display: flex;
   
    gap: 30px;
    padding: 5px;
}
.Request1 {
  display: block;
 
  align-items: center;
  margin: 10px;
  padding: 6px;
}
.Req_for>.form__group_field1{
  display: flex;
  justify-content: space-between;
      gap: 30px;
      padding: 5px;
      margin-right: 0px;
  }
.textarea{

  min-width:200px;
  max-width:200px;
  min-height: 100px;
  max-height: 100px;
 
 }
 .Request1>div>.Name2, .Request2>div>p {
  width: 98px;
}
 .Request1>div {
  display: flex;
  justify-content: space-between;
}
 .Tab{
  margin-left: 15px;
    margin-right: 15px;
    overflow-x: scroll ;
}
.Request1>div>p, .Request2>div>p {
  width: 100px;
}
}
