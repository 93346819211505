.User_register {
  display: flex;
  justify-content: center;
  align-items: center;
 
background-color: rgb(249, 250, 246);
  /* height: 75vh; */
}
.form-group {

  width: 232px;
  height: 30px;
  border-radius: 3px;
  padding: 5px;
  /* background: transparent; */
    border: 0.1px outset;
    /* border-bottom: 1.5px solid #565050; */
}
.zabuni {
  text-shadow: 3px 4px 5px #71a0b8;
  background-color: rgb(226, 231, 232);
  text-align: start;
}
.company_name {

  font-size: 40px;
  font-weight: 700;
  padding: 1% 1% 0% 4%;
  font-family: "Lucida Console", "Courier New", monospace;
}
.company_nam {

  font-size: 40px;
  font-weight: 700;
  padding: 1% 1% 0% 4%;
  font-family: "Lucida Console", "Courier New", monospace;
  color:black;
  padding: 20px;
  margin-top: 40px;
}

.register {
  padding: 14px;
  margin-top: 4px;
  border: none;
  border-radius: 3px;
  background-color: #43baa0;
  color: white;
  width: 134px;
}
.division {
  margin-left: 100px;
}
.lower {
  margin-left: 155px;
  line-height: 33px;
}
.to_center_align {
  margin-left: 40px;
  line-height: 60px;
  font-family: sans-serif;
  font-size: 30px;
  background-image: linear-gradient(to right, rgb(78, 78, 191) , rgb(212, 212, 235));
  border-radius: 15px 50px 30px;
}
.display_flex {
  display: flex;
  /* line-height: 35px; */
  
}
.zab{
  text-shadow: 3px 4px 5px #71a0b8;
  background-color:rgb(249, 250, 246);
  text-align: start;
  padding: 1%;
 
    font-size: 40px;
    font-weight: 700;
    padding: 1%;

}
.label {
  font-weight: 500;
  line-height: 26px;
  font-size: 15px;

  margin-left: 0;

}
.right {
  margin-right: 22px;
}
.main-center {
  background: #eff5ee;

  margin-bottom: 30px;

  padding: 2%;

  justify-content: center;
  border-radius: 20px;

}
.show_pass {
  margin-left: 6px;
}
.bo{
  display: flex;
 
    gap:36px
}
@media only screen and (max-width: 600px){
  .bo{
    display: flex;
    flex-direction: column;
    gap: 20px
  }
  
}
