
.hover:hover
{
    background-color:  rgb(125, 121, 121);
  
  
  }
  .itemcode{
    width: 170px
}
.Name_required{
    width:383%;
    border:0;
    border-bottom: 1px solid #ccc;

}
.Date_of_creation{
    width:116px
}
.effect{
    border : 0;
    border-bottom: 1px solid ;
    width: 157px;
}
  @media only screen and (max-width: 600px) {
.overflow_x{
    overflow-x: scroll;
}
.Itemcode{
    width: 200px
}
.Name_required{
    width:60%;
    
}
.Proceed_by{
    width:200px
}


  } 