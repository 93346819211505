.name1 {
  text-shadow: 3px 4px 5px #71a0b8;
  background-color: rgb(226, 231, 232);
  text-align: start;
}
.Company_name1 {
  font-size: 40px;
  font-weight: 700;
  padding: 1% 1% 0% 4%;
  font-family: "Lucida Console", "Courier New", monospace;
}
.forma {
  display: flex;
  justify-content: center;
  text-align: center;
  /* height: 430px; */

  margin: 22px;


}
.heading1 {
  line-height: 40px;
 
}

  .telephone{
    display:flex;
    padding: 10px 0px 10px 0px;
    gap:20px
  }
.bg {
  background-color: white;
  display: flex;
  justify-content: center;

  padding: 0px 0px 10px;
  border-radius: 0px;
  margin-top: 22px;
 
}
.label3 {
  margin-left: -76px;
  font-weight: 500;
}
.line_spacing {
  line-height: 30px;
}
.label4 {
  margin-left: -111px;
  font-weight: 500;
}
.label1 {
  margin-left: 3px;
  font-weight: 500;
}
.color {
  background-color: rgb(226, 235, 226);
  height: 100vh;
  width: 100%;
}
.register1 {
  padding: 10px;
  margin-top: 4px;
  border: none;
  border-radius: 3px;
 
  background-color: #43baa0;
  color: white;
  width: 130px;
}

.input_field{
  
  background:  #eff5ee;
    border: 0.1px outset;
    outline:none;
    /* border-bottom: 1.5px solid #565050; */
    margin-left: 5px;
    width: 250px;
    height: 35px;
    /* font-size: 18px */
    border-radius: 4px;
}
.tech{
  display:flex;
 
}
.tech2{
  display:flex;
  gap:26px;
  margin-left : 16px
}
.dropdown_2{
  display: flex;
    /* margir-top: 30px; */
    padding: 9px 0px 0px 0px;
    gap: 20px
}