*,
*::after,                  
*::before {
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 100%;
}
body {
  width: 100%;
}
li {
  list-style: none;
} 
a {
  text-decoration: none;
  color:rgb(255, 255, 255);
  font-weight: 500;
  text-decoration-color: rgb(228, 216, 216);
 
  
 
}
a:hover{
text-decoration: underline;
}
.navbar_comapany_name{
  /* margin-left: 35px; */
  color:rgb(226 206 224);
  font-family: Cambria, Cochin, serif;
  font-weight: 600;
  font-size: 30px;
  
} 
 .navbar_comapany{
 
  color:rgb(226 206 224);
  
  font-weight: 600;
  font-size: 30px;
  
} 


.Application{
  display: flex;
  justify-content: center;
}
.gap1:hover {
  background-color: rgb(125, 121, 121);
   background-size: 10px;
 
}
.gap3:hover {
  background-color:  rgb(125, 121, 121);
   background-size: 10px;
 
}
.gap2:hover {
  background-color: rgb(140, 129, 129);
   background-size: 10px;
 
}



.nav__menu {
 
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(147, 88, 115);
  gap: 3rem;
  font-size: 20px;
  font-weight: 7;
  height: 60px;
  margin-right: 0px;
}
.log {
 
  font-size: 19px;
  font-family: 'Roboto', sans-serif; 

  color: white;
  border-radius: 3px;

  display : flex;
justify-content: center;
text-align: center;
width: 160px;

}


.divission {
  display: flex;

  font-family: 'Roboto',sans-serif;
  text-align: center;
  font-size: 20px;
  justify-content: center;
  font-weight: 600;
}
.gap1{
  width: 3px;
  display : flex;
justify-content: center;
text-align: center;
width: 174px


}
.gap8{
  width:200px; 
  display: flex;
  align-items: center;
  justify-content: center;

}
.gap8:hover{
  background-color:  rgb(125, 121, 121);


}
.gap2{
  width: 90px ;
  text-align: center;
}
.gap3{
  width: 130px ;
  text-align: center;
}
.gap9{
  width:100px;
  display: flex;
align-items: center;
justify-content: center;
  
}
.gap9:hover{
  background-color:  rgb(125, 121, 121);
}

.nav__brand {
  text-transform: uppercase;
}

.nav__toggler {
  display: none;
}
.rate_value{
  background: transparent;
    border: none;
    border-bottom: 0.0px solid #565050;
    margin-left: 5px; 
    width: 80%;
    height: 26px;
    font-size: 15px;

	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: rgb(147, 88, 115);
	--mainColorLight: rgb(147, 88, 115);
	--secondaryColor: black;
	--textColor: #eee;
}

.value{
  font-size: 20px;
  font-family:serif
}

.btnn {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.nav_big {
  width: 50px;
  text-align: center;
  padding-left: 35%;
}
.nav__active {
  width: 0px;
}

.log_btn {
  visibility: visible;
  opacity: 1;
  height: 2vh;
  width: 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background-color: #43baa0;
  color: white;
text-decoration: none;
  border-radius: 10px;
  font-size: 20px;
  margin-right: -43px;
}
.to_center_input {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.padding{
  padding:5px;
  margin-bottom: 5px;
  
}
.search{
  background: transparent;
  border: none;
  border-bottom: 1.5px solid #565050;
  margin-left: 5px;
  width: 30%;
  height: 33px;
  font-size: 20px;
}
.to_go_to_bidspages {
  visibility: visible;
  opacity: 1;
  height: 2vh;
  width: 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  background-color: #43baa0;
  color: white;
  margin: 0px 10px;
  border-radius: 3px;
  font-size: 18px;
}
.view_option {
  display: flex;
  justify-content: center;
}
.nav__active {
  transform: translateX(0%);
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);  
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.3rem;
  margin: 0.4rem;
  background: rgb(95, 90, 90);
  transition: 0.3s ease-in;
}
.filter {
  width: 80%;
  padding: 4px;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
}
.Application_name{
 font-size: 25px;
 font-family: none;

}
.Approval_button{
  display: flex;
}  
nav a {
	/* margin: 0 1rem; */
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
  gap: 29px;
    padding: 0px 9px
}
.nav_link1{
  /* width:140px */

}
.nav_link2{
  /* width:156px */
}
.nav_link3{
/* width:52px */
}



@media only screen and (max-width: 600px) {
  header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header div{
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
  .divissio {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto',sans-serif;
    text-align: center;
    font-size: 20px;
    justify-content: space-between;
    font-weight: 600;

    gap: 10px;
}

 
  table,                                               
  td,
  th {
    width: 100%;
    overflow-x: scroll;
    height: 100px;
  }

   .nav__menu {
    position: absolute;
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    background-color: rgb(179, 189, 219);
    gap: 2rem;
    font-size: 20px;
    font-weight: 7;
    height: 50px;
    left: 0px;
    mask-position: fixed;
  }
   .divission {
    overflow-x: scroll;
    flex-direction: column;
    gap: 17px;
    padding: 2%;
    margin: 2%;
    width: 100%;
  } 
  
   .scroll {
    overflow-x: auto;
  }
.Application_name{
  overflow-x: auto;
}
  .nav__toggler {
    display: block;
    cursor: pointer;
    float: right;
    position: absolute;
    z-index: 3;
    float: right;
    right: 0;
  }
  .nav__menu {
    position: fixed; 
     top: 0;
    right: 0;
    height: 100vh;

    background: rgb(171, 171, 254);
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
    overflow-y: none;
  }
  .nav__active {
    transform: translateX(100%);
    display: grid;
    padding: 15px;
    width: 100%;
    z-index: 1;
    background: rgb(171, 171, 254);
  }

  .log_btn {
  
    opacity: 1;
    visibility: visible;

    height: 2vh;
    width: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px 50px;

    background-color: rgb(25, 39, 88);
    color: white;
    margin: 0px -330px;
  }
  .gap {
    margin-top: 8px;
  }
  .to_go_to_bidspages {
    opacity: 1;
    z-index: 0;
  }
  .btnn {
  
    margin-left: 100%;
    margin-top: 4%;
  }
  .nav_link_big {
    width: 100px;
    text-align: center;
    padding-left: 0%;
  }
  .nav_link.Approved_link {
    margin-left: 0px !important;
  }
  .filter {
    width: 100px;
  } 
}


