

.BuyerInventory {
  margin-bottom: 10px;
  display : flex;
  justify-content: center;
  Padding : 8px;
  gap:40px;
  
}
.Description{
  display : flex;

  margin-right: 44%;
  gap: 30px;
  margin-top: 15px
}
.Description>p{
  width: 123px
}
.Save_Item{
  display: flex;
  justify-content: start;
  flex-direction: start;
}
.previous {
  background-color:  #04AA6D;
  color: white;
  border-radius: 50%;
  font-size: 7px;
  width: 50px;
  height: 4px;
}

.next {
  background-color: #04AA6D;
  color: white;
  border-radius: 50%;
}


.form1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}


p>.dropdown {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.SaveItem {
  margin-top: 45px;
  padding: 10px 20px;
  background-color:  #43baa0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.dropdown{
width:170px;
border:none;
border-bottom: 1px solid;
}
.border2{
border:none;
border-bottom: 1px solid;
}
.form1{
min-height: 100vh;
}
.Main2{
min-height: 200vh;
}
.Page {
display: flex;
align-items: center;
justify-content: center;
margin: 20px;
}

.PageButton {
display: flex;
align-items: center;
justify-content: center;
padding: 5px 10px;
margin-right: 25px;
background-color: #43baa0;
border: none;
border-radius: 3px;
cursor: pointer;
font-weight: bold;
font-size: 15px;
}

.PageInput {
padding: 5px;

margin-top : 25px;
border: 1px solid #ccc;
border-radius: 3px;
border: none;
border-bottom: 1px solid;
}

.CurrentPage {
margin: 0 10px;
}
.Inventory{
font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
  text-align: end;
  font-family: monospace;
  table-layout: fixed;
  font-stretch: expanded;
}
.AddItemLink {

text-decoration: none;
}
.AddItemButton {
padding: 10px 20px;
background-color:var(--mainColor);;
border: none;
border-radius: 3px;
cursor: pointer;
margin-right: 20px;
color: white;
}
.Start_date{
font-size: 20px;

}
.Item_Name{

box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

background-color: #fff;

}
.End_Date{
font-size: 20px;
}
.Date_filter{
padding: 10px;
display : flex;
justify-content: start;
gap: 20px
}
.start_input{
border: none;
border-bottom: 1px solid;
width: 15%
}
.item_code_input {
padding: 0px;
border: 1px solid #ccc;

font-size: 15px;
background-color: #fff;
transition: border-color 0.3s ease;
margin-left: 18px;
border: none;
border-bottom: 2px solid;
height: 31px;
}
.filter_section{
padding : 5px;
margin: 2px;
display: flex;
gap: 20px;
margin-bottom: 10px;
margin-top: 20px;
    justify-content: space-between;
}
.search_by_item_code{
  margin-left: 15px;
}
.item_code_input:focus,
.item-code-input:active {
border-color: #888;
}
.Addition_of_item{
display: flex;
gap:30px
}
.Addition_of_item_uom{
display: flex;
gap:20px
}

.Buom{
width: 85px
}
.Auom{
width: 135px
}
.Vatable{
width:77px
}
.Convfac{
width:130px
}
.Addition_of_item_classi{
display: flex;
gap:26px
}
@media only screen and (max-width: 600px){
.AddItemLink {
  margin-left: 20px;
  text-decoration: none;
}
.Main2 {
 
  overflow-x: scroll;
}
.filter_section {
  padding: 5px;
  margin: 2px;
  display: flex;
  flex-direction: column ;
  gap: 20px;
  margin-bottom: 10px;
}
}

