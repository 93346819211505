.To_center_form {
 
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;


}
.input_grp{
  position: relative;
   
}
.input_grp_label{
  position: relative;
    top: 9px;
    left: 10px;
    font-size: 16px;
    color: #cdcdcd;
    font-weight: normal;
    padding: 2px 5px;
    z-index: 5;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.name {
  text-shadow: 3px 4px 5px #71a0b8;

  width: 100%;

 
}
.Company_name {
  margin-left: 2%;
  font-size: 40px;
  font-weight: 700;
  padding: 1%;
 
}

.for_input {
  margin-top: -2px;
  border-style: groove;
  border-width: 2px;
  border-color: #cec9c9;
  margin-left: 5px;
  width: 145px;
  height: 25px;
  border-radius: 3px;
}
.form {
  width: 100%;
  max-width: 390px;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 21px;
  margin: 12px;
  background-color: rgb(225, 243, 210);
  border-radius: 10px;
  box-shadow: 8px 8px 8px rgb(104, 154, 112);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 448px;
  gap:13px
}

.form_aliging_size {
  width: 300px;
}
.register1{
  color:rgb(94, 78, 123);
}

h1 {
  font-size: 35px;

  font-weight: normal;
}
.sign_in {
  line-height: 56px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}


.for_login_input[type="text"],
.for_login_input[type="password"],

.for_login_input {
  margin-top: -2px;
  border-style: groove;
height:36px;
  margin-left: 7px;
  border-radius: 5px;
  width: 95%
}
.tag {
  width: 160px;
  margin-right: 2px;
}

.for_lable_form {
  
display: flex;
  font-weight: 600;
  padding: 5px;
  line-height: 37px;

  font-size: 20px;
}
button {
  position: relative;

  cursor: pointer;
}

.to_center_pass_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.instructions {
  font-size: 1px;

  border-radius: 0.5rem;

  background: #000;

  color: #fff;

  padding: 0.25rem;

  position: relative;

  bottom: -10px;
}



.line {
  display: inline-block;

  font-size: 14px;
}

.sign {

  padding: 10px;

  border: none;

  background-color: rgb(64, 78, 125);

  color: white;
  transition: 1.2s;
  margin: 27px;
  font-family: "Nunito", sans-serif;

  font-size: 16pt;

  padding: 4px;

  margin-left: 23px;

  border-radius: 0.3rem;

  justify-content: center;

  align-items: center;
}
.sign:hover {
  background-color: rgb(38, 51, 104);
}

.ptag {
  margin-top: 3px;
  font-size: 14px;
  width: 116px;
}

.pass {
  margin-top: 10px;

  margin-left: 0.25rem;
}

.line {
  display: inline-block;
  font-size: 14px;
}
#username {
  /* margin-bottom: 15px; */
}
#password {
  /* margin-bottom: 10px; */
}

.fpwd {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign {
  padding: 10px;
  margin-top: 30px;
  border: none;
  background-color:  #43baa0;
  color: white;
}
.ptagg {
  margin-top: 6px;
  margin-left: 8px;
}
.pass {
  margin-top: 10px;

  padding: 100%;
}

.click {


  text-decoration: underline;
}
.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: -25px;
  margin-top: 4px;
}
.offscreen {
  display: none;
}
.space {
  margin-left: 5px;
}
.image_Zabuni{
  height: 82vh;
  width: 89vh;
}

@media only screen and (max-width: 600px) {
  .image_Zabuni{
    height: 35vh;
    width: 100%;
  }
 
  .To_center_form {

    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  
   
  }
  .bottom_footer{
    min-height: 100vh;
  }
  .form {
    width: 85%;
    max-width: 390px;
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 21px;
    margin: 12px;
    background-color: rgb(225, 243, 210);
    border-radius: 10px;
    box-shadow: 8px 8px 8px rgb(104, 154, 112);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 448px;
    gap:13px
  }
 
.parent_div {
  width: 100%;
  display: flex;

  background-color: #dcdcdc;
}

  
}
  