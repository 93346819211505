
.header{
  
  font-size: 20px;
 font-weight: 600;
  margin: 10px 0;
  text-align: center;
  font-family: monospace; 

  table-layout: fixed;

  font-stretch: expanded;
 
}
.tbody{
  text-align: center;
}
.container {
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  
  font-family: 'lato', sans-serif; 
}
.cont{
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  font-family: 'lato', sans-serif; 
  border-collapse: collapse;
}

.headerName{
  
  background-color: #43baa0;
  font-size: 15px;
 text-align: left;
  height: 50px;
  font-weight: 500;
}
.table-row {
padding: 3px;

  
  font-size: 13px;
  color: #100f0f;
 
  height: 30px;
  background-color: #ffffff;
  box-shadow: -11px 0px 29px -11px rgba(0,0,0,0.4);
  overflow-x:auto;
  width:90px;
  
  
}

.table-row:hover  {
 
  
  background-color: rgb(216, 211, 211);
  
 
}

 


.Info{
  border-collapse: separate;
        border-spacing: 0 15px;
        margin-bottom:1em;
}
.Approval_button2{
  display: flex;
  gap:20px

}
.Approve{
  background-color: #43baa0;
  border:none;
  height: 30px;
  border-radius: 3px
}
.View{
  background-color: #43baa0;
  border:none;
  height:30px;
  border-radius: 3px;
  width:50px
}
.div_height{
margin-bottom: 90px;
}


@media only screen and (max-width: 600px) {
  table,                                              
  td,
  th {
    width: 100%;
    overflow-x: auto;
    height: 0px;
  }
  .container{
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    overflow-x: scroll;
    font-family: 'lato', sans-serif; 
  }
} 