header {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 60px;
	background-color: var(--mainColor);
	color: var(--textColor);
	position : fixed;
	z-index: 1000;
	top:0;
	width:100%

}

nav a {
	color: var(--textColor);
	text-decoration: none;
    font-size: 20px;
}

nav a:hover {
	color: var(--secondaryColor);
}
.home_Link{
	width: 45px
}
.Create_new_user_link{
	width: 155px
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
    gap: 24px
}

@media only screen and (max-width: 1024px) {
	header .nav-btn  {
		visibility: visible;
		opacity: 1;
		margin-left: 193px;
		z-index: 1
	}
	.home_Link{
		width: 45px
	}
	.Create_new_user_link{
		width: 190px
	}
	.nav_link{
		width:110px
	}
	.container
	{
		overflow-x: scroll;
	}
    .divissio {
        overflow-x: scroll;
        flex-direction: column;
        gap: 17px;
        padding: 2%;
        margin: 2%;
        width: 100%;
      } 

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav  {
		transform: translateY(100vh);
		z-index: 1;
		align-items: baseline;
	}
	header .responsive_nav .View_detials {
		z-index: 0;
	}
	

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
	.nav-btn .nav-close-btn{
opacity: 0;
	}
	
}